import { UserCircleIcon } from 'lucide-react';

import { Button } from './Button';
import { useRootLoaderData } from '@/hooks/useRootLoaderData';

export const UserNavigation = () => {
  const { currentUser } = useRootLoaderData();

  return (
    <div>
      {!currentUser && (
        <Button to="/login" variant="secondary" size="sm">
          Login
        </Button>
      )}
      {currentUser && (
        <Button
          to="/account"
          variant="secondary"
          size="sm"
          icon={UserCircleIcon}
        >
          Account
        </Button>
      )}
    </div>
  );
};
