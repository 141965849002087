import { useEffect, useRef } from 'react';

import { Outlet, ScrollRestoration, useNavigation } from 'react-router-dom';
import LoadingBar, { type LoadingBarRef } from 'react-top-loading-bar';

import { useRootLoaderData } from '../../hooks/useRootLoaderData';
import { ThemeStyles } from '../ThemeStyles';
import { OrderPageHeader } from '@/components/layout/OrderPageHeader';
import invariant from '@/lib/invariant';

export function OrderRoot() {
  const { currentUser, resultId } = useRootLoaderData();

  invariant(resultId);

  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';

  useEffect(() => {
    document.body.classList.add('bg-app-primary');

    return () => {
      document.body.classList.remove('bg-app-primary');
    };
  }, []);

  const ref = useRef<LoadingBarRef>(null as never);

  useEffect(() => {
    const current = ref.current;
    if (isLoading) {
      current?.continuousStart();
    } else {
      current?.complete();
    }

    return () => {
      current?.complete();
    };
  }, [isLoading]);

  return (
    <>
      <ThemeStyles />
      <div className="min-h-screen bg-app-body font-sans text-xl lg:text-2xl">
        <OrderPageHeader
          user={currentUser}
          resultId={currentUser?.result_id || resultId}
        />

        <main className="bg-app-body pb-10 xl:pb-20">
          <div className="container relative">
            <Outlet context={{ isOrderFlow: true }} />

            <ScrollRestoration />
          </div>
        </main>
      </div>
      <LoadingBar ref={ref} height={5} color="hsl(var(--color-secondary))" />
    </>
  );
}
