import { createBrowserRouter } from 'react-router-dom';

import { AppLoader } from './components/AppLoader';
import ErrorPage from './error-page';
import { OrderRoot } from './routes/order/OrderRoot';
import { Root, rootLoader } from './routes/Root';

export const router = createBrowserRouter(
  [
    {
      path: '/',
      id: 'root',
      loader: rootLoader,
      shouldRevalidate: () => true,
      errorElement: <ErrorPage />,
      hydrateFallbackElement: <AppLoader />,
      children: [
        {
          path: '/',
          element: <Root />,
          children: [
            {
              index: true,
              lazy: () => import('./routes/home'),
            },
            {
              path: 'login/:resultId?',
              lazy: () => import('./routes/login'),
            },
            {
              path: 'search',
              lazy: () => import('./routes/search'),
            },
            {
              path: 'services/:resultId',
              lazy: () => import('./routes/services'),
            },
            {
              path: 'services/:resultId/utilities',
              lazy: () => import('./routes/services/utilities'),
            },
            {
              path: 'services/:resultId/:verticalType/:page?',
              lazy: () => import('./routes/services/$verticalType'),
            },
            {
              path: 'package/:resultId',
              lazy: () => import('./routes/package'),
              id: 'services',
            },
            {
              path: 'package/:resultId/appointment-scheduled',
              lazy: () => import('./routes/package/appointment-scheduled'),
            },
            {
              path: 'register/:resultId?',
              lazy: () => import('./routes/register'),
            },
            {
              path: 'forgot-password',
              lazy: () => import('./routes/forgot-password'),
            },
            {
              path: 'reset-password',
              lazy: () => import('./routes/reset-password'),
            },
            {
              path: 'auth/logout',
              lazy: () => import('./routes/auth/logout'),
            },
            {
              path: 'reset-password/success',
              lazy: () => import('./routes/reset-password/success'),
            },
            {
              path: 'confirm-account',
              lazy: () => import('./routes/confirm-account'),
            },
            {
              path: 'account',
              shouldRevalidate: () => true,
              lazy: () => import('./routes/account'),
            },
            {
              path: 'promotions/:resultId?',
              lazy: () => import('./routes/promotions'),
            },
          ],
        },
        {
          path: '/admin/',
          lazy: () => import('./routes/admin/AdminRoot'),

          children: [
            { index: true, lazy: () => import('./routes/admin') },
            {
              path: 'packages',
              lazy: () => import('./components/admin/AdminOffersRoot'),
              children: [
                { index: true, lazy: () => import('./routes/admin/packages') },
                {
                  path: ':offerId',
                  lazy: () => import('./routes/admin/packages/$offerId'),
                },
                {
                  path: 'new',
                  lazy: () => import('./routes/admin/packages/new'),
                },
              ],
            },

            {
              path: 'questions',
              children: [
                {
                  index: true,
                  lazy: () => import('./routes/admin/questions'),
                },
                {
                  path: 'new',
                  lazy: () => import('./routes/admin/questions/new'),
                },
                {
                  path: ':fieldId',
                  lazy: () => import('./routes/admin/questions/$fieldId'),
                },
              ],
            },
            {
              path: 'add-ons',
              children: [
                { index: true, lazy: () => import('./routes/admin/add-ons') },
                {
                  path: 'new',
                  lazy: () => import('./routes/admin/add-ons/new'),
                },
                {
                  path: ':addOnId',
                  lazy: () => import('./routes/admin/add-ons/$addOnId'),
                },
              ],
            },
            {
              path: 'promotions',
              children: [
                {
                  index: true,
                  lazy: () => import('./routes/admin/promotions'),
                },
                {
                  path: 'tracking',
                  lazy: () => import('./routes/admin/promotions/tracking'),
                },
                {
                  path: 'new',
                  lazy: () => import('./routes/admin/promotions/new'),
                },
                {
                  path: ':promotionId',
                  lazy: () => import('./routes/admin/promotions/$promotionId'),
                },
              ],
            },
          ],
        },

        {
          path: '/order',
          element: <OrderRoot />,
          children: [
            {
              path: 'intro/:resultId',
              lazy: () => import('./routes/order/intro'),
            },
            {
              path: 'register/:resultId',
              lazy: () => import('./routes/register'),
            },
            {
              path: 'confirm-account/:resultId',
              lazy: () => import('./routes/confirm-account'),
            },
            {
              path: 'contact/:resultId',
              lazy: () => import('./routes/order/contact'),
            },
            {
              path: 'questions/:resultId/:verticalType?',
              lazy: () => import('./routes/questions'),
            },
            {
              path: 'review/:resultId',
              lazy: () => import('./routes/review'),
            },
          ],
        },
      ],
    },
  ],
  {
    future: {
      v7_partialHydration: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_skipActionErrorRevalidation: true,
      // v7_startTransition: true,
    },
  }
);
