import { StrictMode } from 'react';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import { RollbarProvider } from 'providers/RollbarProvider';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { queryClient } from './queries/queryClient';
import { router } from './router';
import { AxiosInterceptorsProvider } from '@/providers/AxiosInterceptorsProvider';

axios.defaults.baseURL = import.meta.env.VITE_API_URL as string;

const rootElement = document.getElementById('root');
if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <RollbarProvider>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AxiosInterceptorsProvider>
              <RouterProvider router={router} />
            </AxiosInterceptorsProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </HelmetProvider>
      </RollbarProvider>
    </StrictMode>
  );
}
