import { useCallback } from 'react';

import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

import { PageLogo } from './PageLogo';
import type { ICurrentUserData } from '@/types';

interface Props {
  user: ICurrentUserData | null;
  resultId?: string | null;
}

export function OrderPageHeader(props: Props) {
  const { user, resultId } = props;

  const renderReturnLink = useCallback(() => {
    const className =
      'text-app-secondary font-bold text-sm inline-flex items-center';
    if (resultId) {
      return (
        <Link className={className} to={`/package/${resultId}`}>
          <ArrowLeft className="mr-1 w-4" />
          Back to Cart
        </Link>
      );
    }

    if (user) {
      return (
        <Link className={className} to="/account">
          <ArrowLeft className="mr-1 w-4" />
          Return to Your Account
        </Link>
      );
    }

    return (
      <Link className={className} to="/">
        <ArrowLeft className="mr-1 w-4" />
        Return to Home Page
      </Link>
    );
  }, [user, resultId]);

  return (
    <div className="bg-app-body">
      <header className="relative">
        <div className="container flex items-center justify-between py-4">
          <div>
            <PageLogo
              className="object-left lg:h-[60px] lg:w-[255px]"
              variant="dark"
            />
          </div>

          {renderReturnLink()}
        </div>
      </header>
    </div>
  );
}
