import type React from 'react';

import { ErrorBoundary, Provider } from '@rollbar/react';

import { rollbarConfig } from '@/config/rollbar';

interface IProps {
  children: React.ReactNode;
}

export function RollbarProvider({ children }: IProps) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
