import axios, { type AxiosRequestConfig } from 'axios';

import type { IApiResponse } from '@/types';

export function apiGetRequest<T = unknown, M = unknown>(
  url: string,
  config: AxiosRequestConfig = {}
) {
  config.baseURL = import.meta.env.VITE_API_URL as string;
  return axios.get<IApiResponse<T, M>>(url, config);
}

export function apiPostRequest<T = unknown, M = unknown>(
  url: string,
  data?: unknown,
  config: AxiosRequestConfig = {}
) {
  config.baseURL = import.meta.env.VITE_API_URL as string;
  return axios.post<IApiResponse<T, M>>(url, data, config);
}
