import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { deleteCookie, getCookie } from '../lib/cookies';
import { getCurrentUserQueryKey } from '../queries/currentUserQuery';

const cookieName = 'token' as const;

interface IProps {
  children: React.ReactNode;
}

export function AxiosInterceptorsProvider(props: IProps) {
  const { children } = props;

  const queryClient = useQueryClient();

  useEffect(() => {
    // Add Bearer auth token to all requests
    const requestInterceptor = axios.interceptors.request.use((config) => {
      const token = getCookie(cookieName);

      if (config.headers && token) {
        (config.headers as { Authorization: string }).Authorization =
          `Bearer ${token}`;
      }

      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          deleteCookie(cookieName, { path: '/' });
          queryClient.invalidateQueries({ queryKey: getCurrentUserQueryKey() });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [queryClient]);

  return children;
}
