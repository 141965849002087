import { useIntersectionObserver } from '@uidotdev/usehooks';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';

import { PageLogo } from './PageLogo';
import { TopNav } from './TopNav';

export function PageHeader() {
  // Get routere resultId param
  const { resultId } = useParams<{ resultId: string }>();
  const [topAnchorRef, topAnchorEntry] =
    useIntersectionObserver<HTMLDivElement>({
      threshold: 0,
      root: null,
      rootMargin: '0px',
    });

  return (
    <div className="bg-app-body text-app-text">
      <div ref={topAnchorRef} />
      <header className="relative sm:pb-5 sm:pt-8 lg:pb-8">
        <div
          className={clsx(
            'sm:fixed sm:inset-x-0 sm:top-0 sm:z-50 sm:transition-all sm:duration-300',
            {
              'sm:bg-white sm:shadow-[0px_-10px_24px_rgba(0,0,0,0.1)]':
                (topAnchorEntry?.boundingClientRect.y ?? 0) < 0,
            }
          )}
          data-header
        >
          <div className="container flex items-center justify-between lg:py-2">
            <div>
              <Link to={resultId ? `/services/${resultId}` : '/'}>
                <PageLogo
                  className="object-left lg:h-[60px] lg:w-[255px]"
                  variant="dark"
                />
              </Link>
            </div>
            <TopNav />
          </div>
        </div>

        <div className="sm:pb-5" />
      </header>
    </div>
  );
}
