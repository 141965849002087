import convert from 'color-convert';
import { Helmet } from 'react-helmet-async';

import { useCurrentPartner } from '@/hooks/useCurrentPartner';

function partnerColorValueToHSL(value: string | null, variable: string) {
  if (!value) {
    return null;
  }
  try {
    const hsl = convert.hex.hsl(value);
    return `--color-${variable}: ${hsl[0]} ${hsl[1]}% ${hsl[2]}%;`;
  } catch (e) {
    return null;
  }
}

export const ThemeStyles = () => {
  const currentPartner = useCurrentPartner();

  if (!currentPartner) {
    return null;
  }

  return (
    <Helmet>
      <style type="text/css">
        {`
          :root {
            ${partnerColorValueToHSL(currentPartner.PrimaryColor, 'primary')}
            ${partnerColorValueToHSL(
              currentPartner.PrimaryTextColor,
              'primary-foreground'
            )}
            ${partnerColorValueToHSL(
              currentPartner.SecondaryColor,
              'secondary'
            )}
            ${partnerColorValueToHSL(
              currentPartner.SecondaryTextColor,
              'secondary-foreground'
            )}
            ${partnerColorValueToHSL(currentPartner.TertiaryColor, 'accent')}
            ${partnerColorValueToHSL(
              currentPartner.PrimaryTextColor ?? '#000000',
              'accent-foreground'
            )}
          }
        `}
      </style>
    </Helmet>
  );
};
