import type { AxiosRequestConfig } from 'axios';

import { apiGetRequest } from '../apiRequests';
import type { ICurrentUserData } from '@/types';

export function getCurrentUserAccount(token?: string) {
  const config: AxiosRequestConfig = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};
  return apiGetRequest<ICurrentUserData>('/user/account', config);
}
